export const defineCustomerType = {
  1 : {
    value: 1, label: 'Cá nhân'
  },
  2 : {
    value: 2, label: 'Doanh nghiệp'
  },
  3 : {
    value: 3, label: 'Tiềm năng'
  }
}

export default {
    // Reset
    FACEBOOK_RESET: 'SC_FACEBOOK_RESET',
    FACEBOOK_UPDATE: 'SC_FACEBOOK_UPDATE',

    FACEBOOK_GETUSERSTATUS_FAILURE: 'SC_FACEBOOK_GETUSERSTATUS_FAILURE',
    FACEBOOK_GETUSERSTATUS_SUCCESS: 'SC_FACEBOOK_GETUSERSTATUS_SUCCESS',

    FACEBOOK_CONNECT_FAILURE: 'SC_FACEBOOK_CONNECT_FAILURE',
    FACEBOOK_CONNECT_SUCCESS: 'SC_FACEBOOK_CONNECT_SUCCESS',

    FACEBOOK_LOGIN_FAILURE: 'SC_FACEBOOK_LOGIN_FAILURE',
    FACEBOOK_LOGIN_SUCCESS: 'SC_FACEBOOK_LOGIN_SUCCESS',
    FACEBOOK_LOGIN_REQUEST: 'SC_FACEBOOK_LOGIN_REQUEST',

    FACEBOOK_SYNC_FAILURE: 'SC_FACEBOOK_SYNC_FAILURE',
    FACEBOOK_SYNC_SUCCESS: 'SC_FACEBOOK_SYNC_SUCCESS',
    FACEBOOK_SYNC_REQUEST: 'SC_FACEBOOK_SYNC_REQUEST',

    FACEBOOK_SYNC_PAGE_REQUEST: 'FACEBOOK_SYNC_PAGE_REQUEST',
    FACEBOOK_SYNC_PAGE_SUCCESS: 'FACEBOOK_SYNC_PAGE_SUCCESS',
    FACEBOOK_SYNC_PAGE_FAILURE: 'FACEBOOK_SYNC_PAGE_FAILURE',

    FACEBOOK_GETUSERACCESSTOKEN_FAILURE: 'SC_FACEBOOK_GETUSERACCESSTOKEN_FAILURE',
    FACEBOOK_GETUSERACCESSTOKEN_SUCCESS: 'SC_FACEBOOK_GETUSERACCESSTOKEN_SUCCESS',
    FACEBOOK_GETUSERACCESSTOKEN_REQUEST: 'SC_FACEBOOK_GETUSERACCESSTOKEN_REQUEST',

    FACEBOOK_GETLISTPAGE_FAILURE: 'SC_FACEBOOK_GETLISTPAGE_FAILURE',
    FACEBOOK_GETLISTPAGE_SUCCESS: 'SC_FACEBOOK_GETLISTPAGE_SUCCESS',
    FACEBOOK_GETLISTPAGE_REQUEST: 'SC_FACEBOOK_GETLISTPAGE_REQUEST',

    PAGE_CONNECT: 'PAGE_CONNECT',
    PAGE_CONNECT_SELECTED: 'PAGE_CONNECT_SELECTED',
    CLEAR_AUTH_RESPONSE: 'CLEAR_AUTH_RESPONSE',

    UPLOAD_FILE_REQUEST: 'SC_FACEBOOK_UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_SUCCESS: 'SC_FACEBOOK_UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: 'SC_FACEBOOK_UPLOAD_FILE_FAILURE',
    CLEAR_UPLOAD_FILE: 'SC_FACEBOOK_CLEAR_UPLOAD_FILE',

    GET_LIST_CONVERSATION_REQUEST: 'GET_LIST_CONVERSATION_REQUEST',
    GET_LIST_CONVERSATION_FAILURE: 'GET_LIST_CONVERSATION_FAILURE',
    GET_LIST_CONVERSATION_SUCCESS: 'GET_LIST_CONVERSATION_SUCCESS',

    CLEAR_CONVERSATION: 'CLEAR_CONVERSATION',

    APPEND_LIST_CONVERSATION: 'APPEND_LIST_CONVERSATION',
    MQTT_LIST_CONVERSATION: 'MQTT_LIST_CONVERSATION',

    GET_MESSAGE_FACEBOOK_USER_REQUEST: 'GET_MESSAGE_FACEBOOK_USER_REQUEST',
    GET_MESSAGE_FACEBOOK_USER_SUCCESS: 'GET_MESSAGE_FACEBOOK_USER_SUCCESS',
    GET_MESSAGE_FACEBOOK_USER_FAILURE: 'GET_MESSAGE_FACEBOOK_USER_FAILURE',

    GET_PAGE_TOKEN_REQUEST: 'GET_PAGE_TOKEN_REQUEST',
    GET_PAGE_TOKEN_FAILURE: 'GET_PAGE_TOKEN_FAILURE',
    GET_PAGE_TOKEN_SUCCESS: 'GET_PAGE_TOKEN_SUCCESS',

    FACEBOOOK_CHANGEVIEW: 'SC_FACEBOOK_CHANGEVIEW',

    CONVERSATION_SELECTED: 'CONVERSATION_SELECTED',

    GET_FACEBOOK_USER_REQUEST: 'GET_FACEBOOK_USER_REQUEST',
    GET_FACEBOOK_USER_SUCCESS: 'GET_FACEBOOK_USER_SUCCESS',
    GET_FACEBOOK_USER_FAILURE: 'GET_FACEBOOK_USER_FAILURE',

    GET_FACEBOOK_HASHTAG_REQUEST: 'GET_FACEBOOK_HASHTAG_REQUEST',
    GET_FACEBOOK_HASHTAG_SUCCESS: 'GET_FACEBOOK_HASHTAG_SUCCESS',
    GET_FACEBOOK_HASHTAG_FAILURE: 'GET_FACEBOOK_HASHTAG_FAILURE',

    DELETE_FACEBOOK_HASHTAG_SUCCESS: 'DELETE_FACEBOOK_HASHTAG_SUCCESS',
    DELETE_FACEBOOK_HASHTAG_FAILURE: 'DELETE_FACEBOOK_HASHTAG_FAILURE',

    CREATE_FACEBOOK_HASHTAG_SUCCESS: 'CREATE_FACEBOOK_HASHTAG_SUCCESS',
    CREATE_FACEBOOK_HASHTAG_FAILURE: 'CREATE_FACEBOOK_HASHTAG_FAILURE',

    UPDATE_FACEBOOK_HASHTAG_SUCCESS: 'UPDATE_FACEBOOK_HASHTAG_SUCCESS',
    UPDATE_FACEBOOK_HASHTAG_FAILURE: 'UPDATE_FACEBOOK_HASHTAG_FAILURE',

    DELETE_NOTE_FACEBOOK_USER_SUCCESS: 'DELETE_NOTE_FACEBOOK_USER_SUCCESS',
    DELETE_NOTE_FACEBOOK_USER_FAILURE: 'DELETE_NOTE_FACEBOOK_USER_FAILURE',

    CREATE_NOTE_FACEBOOK_USER_SUCCESS: 'CREATE_NOTE_FACEBOOK_USER_SUCCESS',
    CREATE_NOTE_FACEBOOK_USER_FAILURE: 'CREATE_NOTE_FACEBOOK_USER_FAILURE',

    UPDATE_FACEBOOK_USER_HASHTAG_SUCCESS: 'UPDATE_FACEBOOK_USER_HASHTAG_SUCCESS',
    UPDATE_FACEBOOK_USER_HASHTAG_FAILURE: 'UPDATE_FACEBOOK_USER_HASHTAG_FAILURE',

    UPDATE_FACEBOOK_USER_REQUEST: 'UPDATE_FACEBOOK_USER_REQUEST',
    UPDATE_FACEBOOK_USER_SUCCESS: 'UPDATE_FACEBOOK_USER_SUCCESS',
    UPDATE_FACEBOOK_USER_FAILURE: 'UPDATE_FACEBOOK_USER_FAILURE',
    UPDATE_FACEBOOK_USER_EXIST: 'UPDATE_UPDATE_FACEBOOK_USER_EXIST',

    CREATE_ORDER_FACEBOOK_USER_REQUEST: 'SC_CREATE_ORDER_FACEBOOK_USER_REQUEST',
    CREATE_ORDER_FACEBOOK_USER_SUCCESS: 'SC_CREATE_ORDER_FACEBOOK_USER_SUCCESS',
    CREATE_ORDER_FACEBOOK_USER_FAILURE: 'SC_CREATE_ORDER_FACEBOOK_USER_FAILURE',

    GET_ORDER_FACEBOOK_USER_REQUEST: 'SC_GET_ORDER_FACEBOOK_USER_REQUEST',
    GET_ORDER_FACEBOOK_USER_SUCCESS: 'SC_GET_ORDER_FACEBOOK_USER_SUCCESS',
    GET_ORDER_FACEBOOK_USER_FAILURE: 'SC_GET_ORDER_FACEBOOK_USER_FAILURE',

    // #region grapql facebook
    SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

    GET_FANPAGE_REQUEST: 'GET_FANPAGE_REQUEST',
    GET_FANPAGE_SUCCESS: 'GET_FANPAGE_SUCCESS',
    GET_FANPAGE_FAILURE: 'GET_FANPAGE_FAILURE',

    GET_LIST_PAGE_CONNECT_REQUEST: 'GET_LIST_PAGE_CONNECT_REQUEST',
    GET_LIST_PAGE_CONNECT_SUCCESS: 'GET_LIST_PAGE_CONNECT_SUCCESS',
    GET_LIST_PAGE_CONNECT_FAILURE: 'GET_LIST_PAGE_CONNECT_FAILURE',

    DELETE_PAGE_CONNECT_REQUEST: 'DELETE_PAGE_CONNECT_REQUEST',
    DELETE_PAGE_CONNECT_SUCCESS: 'DELETE_PAGE_CONNECT_SUCCESS',
    DELETE_PAGE_CONNECT_FAILURE: 'DELETE_PAGE_CONNECT_FAILURE',
    // #endregion

    GET_ADDRESS_BY_MEMBERID_REQUEST: 'GET_ADDRESS_BY_MEMBERID_REQUEST',
    GET_ADDRESS_BY_MEMBERID_SUCCESS: 'GET_ADDRESS_BY_MEMBERID_SUCCESS',
    GET_ADDRESS_BY_MEMBERID_FAILURE: 'GET_ADDRESS_BY_MEMBERID_FAILURE',

    GET_DATA_MEDIA_REQUEST: 'GET_DATA_MEDIA_REQUEST',
    GET_DATA_MEDIA_SUCCESS: 'GET_DATA_MEDIA_SUCCESS',
    GET_DATA_MEDIA_FAILURE: 'GET_DATA_MEDIA_FAILURE',

    PUSH_MEDIDA_FILE: 'PUSH_MEDIDA_FILE',
    PUSH_MEDIDA_IMAGE: 'PUSH_MEDIDA_IMAGE',

    CLEAR_USER_MESSAGE_ORDER: 'SC_CLEAR_USER_MESSAGE_ORDER',
};

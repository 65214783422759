// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // #region position
  GET_POSITIONS_REQUEST: 'GET_POSITIONS_REQUEST',
  GET_POSITIONS_SUCCESS: 'GET_POSITIONS_SUCCESS',
  GET_POSITIONS_FAILURE: 'GET_POSITIONS_FAILURE',

  GET_POSITION_REQUEST: 'GET_POSITION_REQUEST',
  GET_POSITION_SUCCESS: 'GET_POSITION_SUCCESS',
  GET_POSITION_FAILURE: 'GET_POSITION_FAILURE',

  CREATE_POSITION_REQUEST: 'CREATE_POSITION_REQUEST',
  CREATE_POSITION_SUCCESS: 'CREATE_POSITION_SUCCESS',
  CREATE_POSITION_FAILURE: 'CREATE_POSITION_FAILURE',

  UPDATE_POSITION_REQUEST: 'UPDATE_POSITION_REQUEST',
  UPDATE_POSITION_SUCCESS: 'UPDATE_POSITION_SUCCESS',
  UPDATE_POSITION_FAILURE: 'UPDATE_POSITION_FAILURE',

  DELETE_POSITION_REQUEST: 'DELETE_POSITION_REQUEST',
  DELETE_POSITION_SUCCESS: 'DELETE_POSITION_SUCCESS',
  DELETE_POSITION_FAILURE: 'DELETE_POSITION_FAILURE',
  // #endregion
};

export default {
  GET_ALL_ITEMS_REQUEST: 'GET_ALL_ITEMS_REQUEST',
  GET_ALL_ITEMS_SUCCESS: 'GET_ALL_ITEMS_SUCCESS',
  GET_ALL_ITEMS_FAILURE: 'GET_ALL_ITEMS_FAILURE',

  GET_ALL_ITEMS_COLUMN_REQUEST: 'GET_ALL_ITEMS_COLUMN_REQUEST',
  GET_ALL_ITEMS_COLUMN_SUCCESS: 'GET_ALL_ITEMS_COLUMN_SUCCESS',
  GET_ALL_ITEMS_COLUMN_FAILURE: 'GET_ALL_ITEMS_COLUMN_FAILURE',

  GET_ALL_ITEMS_REPLACE_COLUMN_REQUEST: 'GET_ALL_ITEMS_REPLACE_COLUMN_REQUEST',
  GET_ALL_ITEMS_REPLACE_COLUMN_SUCCESS: 'GET_ALL_ITEMS_REPLACE_COLUMN_SUCCESS',
  GET_ALL_ITEMS_REPLACE_COLUMN_FAILURE: 'GET_ALL_ITEMS_REPLACE_COLUMN_FAILURE',

  DELETE_ITEMS_COLUMN: 'DELETE_ITEMS_COLUMN',

  GET_TYPE_DOCUMENTS_REQUEST: 'GET_TYPE_DOCUMENTS_REQUEST',
  GET_TYPE_DOCUMENTS_SUCCESS: 'GET_TYPE_DOCUMENTS_SUCCESS',
  GET_TYPE_DOCUMENTS_FAILURE: 'GET_TYPE_DOCUMENTS_FAILURE',

  CREATE_DIR_REQUEST: 'CREATE_DIR_REQUEST',
  CREATE_DIR_SUCCESS: 'CREATE_DIR_SUCCESS',
  CREATE_DIR_FAILURE: 'CREATE_DIR_FAILURE',

  CREATE_DOCUMENT_REQUEST: 'CREATE_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_SUCCESS: 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE: 'CREATE_DOCUMENT_FAILURE',

  UPDATE_DIR_REQUEST: 'UPDATE_DIR_REQUEST',
  UPDATE_DIR_SUCCESS: 'UPDATE_DIR_SUCCESS',
  UPDATE_DIR_FAILURE: 'UPDATE_DIR_FAILURE',

  UPDATE_FILE_REQUEST: 'UPDATE_FILE_REQUEST',
  UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
  UPDATE_FILE_FAILURE: 'UPDATE_FILE_FAILURE',

  UPDATE_DOCUMENT_REQUEST: 'UPDATE_DOCUMENT_REQUEST',
  UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',
  UPDATE_DOCUMENT_FAILURE: 'UPDATE_DOCUMENT_FAILURE',

  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',

  DELETE_ITEMS_REQUEST: 'DELETE_ITEMS_REQUEST',
  DELETE_ITEMS_SUCCESS: 'DELETE_ITEMS_SUCCESS',
  DELETE_ITEMS_FAILURE: 'DELETE_ITEMS_FAILURE',

  MOVE_ITEMS_REQUEST: 'MOVE_ITEMS_REQUEST',
  MOVE_ITEMS_SUCCESS: 'MOVE_ITEMS_SUCCESS',
  MOVE_ITEMS_FAILURE: 'MOVE_ITEMS_FAILURE',

  ADD_ALL_ITEMS_COLUMN_ARRAY: 'ADD_ALL_ITEMS_COLUMN_ARRAY',

  GET_INFORMATION_FILE_REQUEST: 'GET_INFORMATION_FILE_REQUEST',
  GET_INFORMATION_FILE_SUCCESS: 'GET_INFORMATION_FILE_SUCCESS',
  GET_INFORMATION_FILE_FAILURE: 'GET_INFORMATION_FILE_FAILURE',

  GET_INFORMATION_DIRECTORY_REQUEST: 'GET_INFORMATION_DIRECTORY_REQUEST',
  GET_INFORMATION_DIRECTORY_SUCCESS: 'GET_INFORMATION_DIRECTORY_SUCCESS',
  GET_INFORMATION_DIRECTORY_FAILURE: 'GET_INFORMATION_DIRECTORY_FAILURE',

  CLEAR_INFORMMATION_DATA: 'CLEAR_INFORMMATION_DATA',

  DOWLOAD_FILE_REQUEST: 'DOWLOAD_FILE_REQUEST',
  DOWLOAD_FILE_SUCCESS: 'DOWLOAD_FILE_SUCCESS',
  DOWLOAD_FILE_FAILURE: 'DOWLOAD_FILE_FAILURE',

  GET_TAG_REQUEST: 'GET_TAG_REQUEST',
  GET_TAG_SUCCESS: 'GET_TAG_SUCCESS',
  GET_TAG_FAILURE: 'GET_TAG_FAILURE',

  GET_LIST_SHARE_REQUEST: 'GET_LIST_SHARE_REQUEST',
  GET_LIST_SHARE_SUCCESS: 'GET_LIST_SHARE_SUCCESS',
  GET_LIST_SHARE_FAILURE: 'GET_LIST_SHARE_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  GET_DEPARTMENT_REQUEST: 'GET_DEPARTMENT_REQUEST',
  GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
  GET_DEPARTMENT_FAILURE: 'GET_DEPARTMENT_FAILURE',

  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  SHARE_REQUEST: 'SHARE_REQUEST',
  SHARE_SUCCESS: 'SHARE_SUCCESS',
  SHARE_FAILURE: 'SHARE_FAILURE',

  POST_TAG_REQUEST: 'POST_TAG_REQUEST',
  POST_TAG_SUCCESS: 'POST_TAG_SUCCESS',
  POST_TAG_FAILURE: 'POST_TAG_FAILURE',

  SEARCH_ALL_REQUEST: 'SEARCH_ALL_REQUEST',
  SEARCH_ALL_SUCCESS: 'SEARCH_ALL_SUCCESS',
  SEARCH_ALL_FAILURE: 'SEARCH_ALL_FAILURE',

  SELECTED_FILE: 'SELECTED_FILE',

  SHOW_SEARCH_FUNCTION: 'SHOW_SEARCH_FUNCTION',
  HIDE_SEARCH_FUNCTION: 'HIDE_SEARCH_FUNCTION',
};

export default {
  // #region auth
  LOG_IN_REQUEST: 'LOG_IN_REQUEST',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',
  LOG_OUT: 'LOG_OUT',
  // #endregion

  // #region global
  TRIGGER_SIDEBAR: 'TRIGGER_SIDEBAR',
  // #endregion

  // #region create global
  CREATE_GLOBAL_REQUEST: 'CREATE_GLOBAL_REQUEST',
  CREATE_GLOBAL_SUCCESS: 'CREATE_GLOBAL_SUCCESS',
  CREATE_GLOBAL_FAILURE: 'CREATE_GLOBAL_FAILURE',
  // #endregion

  // #region update global
  UPDATE_GLOBAL_REQUEST: 'UPDATE_GLOBAL_REQUEST',
  UPDATE_GLOBAL_SUCCESS: 'UPDATE_GLOBAL_SUCCESS',
  UPDATE_GLOBAL_FAILURE: 'UPDATE_GLOBAL_FAILURE',
  // #endregion

  // #region update global
  DELETE_GLOBAL_REQUEST: 'DELETE_GLOBAL_REQUEST',
  DELETE_GLOBAL_SUCCESS: 'DELETE_GLOBAL_SUCCESS',
  DELETE_GLOBAL_FAILURE: 'DELETE_GLOBAL_FAILURE',
  // #endregion

  // #region confirm
  SHOW_CONFIRM_MODAL: 'SHOW_CONFIRM_MODAL',
  HIDE_CONFIRM_MODAL: 'HIDE_CONFIRM_MODAL',
  // #endregion

  // #region get by user
  GET_BY_USER_REQUEST: 'GET_BY_USER_REQUEST',
  GET_BY_USER_SUCCESS: 'GET_BY_USER_SUCCESS',
  GET_BY_USER_FAILURE: 'GET_BY_USER_FAILURE',
  // #endregion

  // #region announce
  SHOW_ANNOUNCE_MODAL: 'SHOW_ANNOUNCE_MODAL',
  HIDE_ANNOUNCE_MODAL: 'HIDE_ANNOUNCE_MODAL',
  // #endregion

  // #region announce
  SHOW_NOTIFY: 'SHOW_NOTIFY',
  HIDE_NOTIFY: 'HIDE_NOTIFY',
  TYPE_NOTIFY: 'TYPE_NOTIFY',
  COUNT_NOTIFY: 'COUNT_NOTIFY',
  // #endregion
};

const OFFER_TYPE = {
  PERCENT: 1,
  DIRECT: 2,
  HARD: 3,
  GIFT: 4,
  TRANSPORT: 5,
  ISPAYMENTFORM: 6
};

const TYPEVALUE  = {
  MONEY: 1, // theo đ
  PERCENT: 2, // theo %
};

export { OFFER_TYPE, TYPEVALUE };

export default {
  // #region COUPON
  GET_COUPONS_REQUEST: 'GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS: 'GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILURE: 'GET_COUPONS_FAILURE',

  GET_COUPON_REQUEST: 'GET_COUPON_REQUEST',
  GET_COUPON_SUCCESS: 'GET_COUPON_SUCCESS',
  GET_COUPON_FAILURE: 'GET_COUPON_FAILURE',

  CREATE_COUPON_REQUEST: 'CREATE_COUPON_REQUEST',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAILURE: 'CREATE_COUPON_FAILURE',

  UPDATE_COUPON_REQUEST: 'UPDATE_COUPON_REQUEST',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILURE: 'UPDATE_COUPON_FAILURE',

  DELETE_COUPON_REQUEST: 'DELETE_COUPON_REQUEST',
  DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAILURE: 'DELETE_COUPON_FAILURE',
  // #endregion

  // #region RSERROR
  GET_RSERROR_REQUEST: 'GET_RSERROR_REQUEST',
  GET_RSERROR_SUCCESS: 'GET_RSERROR_SUCCESS',
  GET_RSERROR_FAILURE: 'GET_RSERROR_FAILURE',
  // #endregion

  // #region CUSTOMER_TYPE
  GET_CUSTOMER_TYPE_REQUEST: 'GET_CUSTOMER_TYPE_REQUEST',
  GET_CUSTOMER_TYPE_SUCCESS: 'GET_CUSTOMER_TYPE_SUCCESS',
  GET_CUSTOMER_TYPE_FAILURE: 'GET_CUSTOMER_TYPE_FAILURE',
  // #endregion
};

export const CATEGORY_TYPE = {
  PRODUCT: 1,
  EQUIPMENT: 2,
  CONSUMABLE_SUPPLIES: 3,
  ALL: 4,
};

export const PANEL_TYPES = {
  INFORMATION: 'information',
  MATERIAL: 'material',
};

import styled from 'styled-components';

export const ResetStyle = styled.div`
  .ant-picker {
    padding: 4px 11px 4px !important;
  }

  .bw_frm_box input {
    padding: 4px 11px !important;
  }
`;

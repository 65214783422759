const PERMISSION = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  UNPERMISSION: 'UNPERMISSION',
};

const PERMISSION_DOCUMENT = {
  FM_CREATE_DOCUMENT_MANAGER: 'FM_CREATE_DOCUMENT_MANAGER',
  FM_EDIT_DOCUMENT_MANAGER: 'FM_EDIT_DOCUMENT_MANAGER',
  FM_DELETE_DOCUMENT_MANAGER: 'FM_DELETE_DOCUMENT_MANAGER',
  MD_DOCUMENTTYPE_ADD: "MD_DOCUMENTTYPE_ADD",
  MD_DOCUMENTTYPE_EDIT: "MD_DOCUMENTTYPE_EDIT",
  MD_DOCUMENTTYPE_VIEW: "MD_DOCUMENTTYPE_VIEW",
  MD_DOCUMENTTYPE_DEL: "MD_DOCUMENTTYPE_DEL",
};

const MAX_SIZE = 4000000000;

export {
  PERMISSION,
  PERMISSION_DOCUMENT,
  MAX_SIZE
};

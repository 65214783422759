/* eslint-disable import/no-anonymous-default-export */
export default {
  // #region STOCKS_TAKE_REQUEST
  GET_STOCKS_TAKE_REQUESTS_REQUEST: 'GET_STOCKS_TAKE_REQUESTS_REQUEST',
  GET_STOCKS_TAKE_REQUESTS_SUCCESS: 'GET_STOCKS_TAKE_REQUESTS_SUCCESS',
  GET_STOCKS_TAKE_REQUESTS_FAILURE: 'GET_STOCKS_TAKE_REQUESTS_FAILURE',

  GET_STOCKS_TAKE_REQUEST_REQUEST: 'GET_STOCKS_TAKE_REQUEST_REQUEST',
  GET_STOCKS_TAKE_REQUEST_SUCCESS: 'GET_STOCKS_TAKE_REQUEST_SUCCESS',
  GET_STOCKS_TAKE_REQUEST_FAILURE: 'GET_STOCKS_TAKE_REQUEST_FAILURE',

  CREATE_STOCKS_TAKE_REQUEST_REQUEST: 'CREATE_STOCKS_TAKE_REQUEST_REQUEST',
  CREATE_STOCKS_TAKE_REQUEST_SUCCESS: 'CREATE_STOCKS_TAKE_REQUEST_SUCCESS',
  CREATE_STOCKS_TAKE_REQUEST_FAILURE: 'CREATE_STOCKS_TAKE_REQUEST_FAILURE',

  UPDATE_STOCKS_TAKE_REQUEST_REQUEST: 'UPDATE_STOCKS_TAKE_REQUEST_REQUEST',
  UPDATE_STOCKS_TAKE_REQUEST_SUCCESS: 'UPDATE_STOCKS_TAKE_REQUEST_SUCCESS',
  UPDATE_STOCKS_TAKE_REQUEST_FAILURE: 'UPDATE_STOCKS_TAKE_REQUEST_FAILURE',

  DELETE_STOCKS_TAKE_REQUEST_REQUEST: 'DELETE_STOCKS_TAKE_REQUEST_REQUEST',
  DELETE_STOCKS_TAKE_REQUEST_SUCCESS: 'DELETE_STOCKS_TAKE_REQUEST_SUCCESS',
  DELETE_STOCKS_TAKE_REQUEST_FAILURE: 'DELETE_STOCKS_TAKE_REQUEST_FAILURE',
  // #endregion

  //#region stocks-take-type
  GET_STOCKS_TAKE_TYPES_REQUEST: 'GET_STOCKS_TAKE_TYPES_REQUEST',
  GET_STOCKS_TAKE_TYPES_SUCCESS: 'GET_STOCKS_TAKE_TYPES_SUCCESS',
  GET_STOCKS_TAKE_TYPES_FAILURE: 'GET_STOCKS_TAKE_TYPES_FAILURE',
  //#endregion

  //#region department
  GET_DEPARTMENTS_REQUEST: 'GET_DEPARTMENTS_REQUEST',
  GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_FAILURE: 'GET_DEPARTMENTS_FAILURE',
  //#endregion

  //#region
  GET_STOCKS_REQUEST: 'GET_STOCKS_REQUEST',
  GET_STOCKS_SUCCESS: 'GET_STOCKS_SUCCESS',
  GET_STOCKS_FAILURE: 'GET_STOCKS_FAILURE',
  //#endregion

  //#region stocks take request
  GET_STOCKS_TAKE_TYPE_REQUEST: 'GET_STOCKS_TAKE_TYPE_REQUEST',
  GET_STOCKS_TAKE_TYPE_SUCCESS: 'GET_STOCKS_TAKE_TYPE_SUCCESS',
  GET_STOCKS_TAKE_TYPE_FAILURE: 'GET_STOCKS_TAKE_TYPE_FAILURE',
  //#endregion

  GET_LIST_PRODUCT_REQUEST: 'GET_LIST_PRODUCT_REQUEST',
  GET_LIST_PRODUCT_SUCCESS: 'GET_LIST_PRODUCT_SUCCESS',
  GET_LIST_PRODUCT_FAILURE: 'GET_LIST_PRODUCT_FAILURE',

  SET_REVIEW_DATA: 'SET_REVIEW_DATA',
};
